export default {
  MAX_ATTACHMENT_SIZE: 5000000,

  s3: {
      REGION: "us-east-1",
      BUCKET: "khnotes-app-uploads"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://i52gyxx004.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_x4UsYshs2",
      APP_CLIENT_ID: "5pg85cs73b1hu3olc3kga1ha68",
      IDENTITY_POOL_ID: "us-east-1:fb991741-628e-485c-824f-fa1d2ebaf571"
    }
  };
  